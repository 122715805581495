(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@audi/audi-ui-react-v2"), require("styled-components"), require("react"), require("@feature-hub/react"));
	else if(typeof define === 'function' && define.amd)
		define(["@audi/audi-ui-react-v2", "styled-components", "react", "@feature-hub/react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@audi/audi-ui-react-v2"), require("styled-components"), require("react"), require("@feature-hub/react")) : factory(root["@audi/audi-ui-react-v2"], root["styled-components"], root["react"], root["@feature-hub/react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__2053__, __WEBPACK_EXTERNAL_MODULE__8694__, __WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__9544__) => {
return 